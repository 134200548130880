.upper_corner {
    height: 1rem;
    width: 1rem;
    background: #f6f6f6;
    position: relative;
    overflow: hidden; /* Ensure anything outside the div is hidden */
}

.upper_corner:before {
    content: "";
    position: absolute;
    top: -8px; /* Shift the triangle above the div */
    left: -8px; /* Shift the triangle to the left */
    width: 34%;
    height: 36%;
    border-radius: 100%; /* Makes the pseudo-element curved */
    box-shadow: 0 0 0 18px #800024; /* Creates the 'dent' effect with the curved cut */
}

.bottom_corner {
    height: 1rem;
    width: 1rem;
    background: #f6f6f6;
    position: relative;
    overflow: hidden; /* Ensure anything outside the div is hidden */
}

.bottom_corner:before {
    content: "";
    position: absolute;
    bottom: -8px; 
    left: -8px; 
    width: 34%;
    height: 36%;
    border-radius: 100%; /* Makes the pseudo-element curved */
    box-shadow: 0 0 0 18px #800024; /* Creates the 'dent' effect with the curved cut */
}
