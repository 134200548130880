.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
 }
 
 .loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
 }
 
 .loader:before {
  animation: before8 2s infinite;
 }
 
 .loader:after {
  animation: after6 2s infinite;
 }
 
 @keyframes before8 {
  0% {
   width: 0.5em;
   box-shadow: 1em -0.5em #800024, -1em 0.5em #800024;
  }
 
  35% {
   width: 2.5em;
   box-shadow: 0 -0.5em #800024, 0 0.5em #800024;
  }
 
  70% {
   width: 0.5em;
   box-shadow: -1em -0.5em #800024, 1em 0.5em #800024;
  }
 
  100% {
   box-shadow: 1em -0.5em #800024, -1em 0.5em #800024;
  }
 }
 
 @keyframes after6 {
  0% {
   height: 0.5em;
   box-shadow: 0.5em 1em #800024, -0.5em -1em #800024;
  }
 
  35% {
   height: 2.5em;
   box-shadow: 0.5em 0 #800024, -0.5em 0 #800024;
  }
 
  70% {
   height: 0.5em;
   box-shadow: 0.5em -1em #800024, -0.5em 1em #800024;
  }
 
  100% {
   box-shadow: 0.5em 1em #800024, -0.5em -1em #800024;
  }
 }
 
 .loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
 }