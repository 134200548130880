/* Hide the scrollbar initially */
::-webkit-scrollbar {
    width: 0.4em; /* Adjust width as needed */
    background-color: transparent;
}

/* Handle or Thumb */
::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 0.2em;
    transition: opacity 0.3s;
    opacity: 0; /* Initially hidden */
}

/* Display the thumb when hovering or dragging */
:hover::-webkit-scrollbar-thumb,
:active::-webkit-scrollbar-thumb {
    opacity: 1; /* Show on hover and active */
}

/* Track or Trough */
::-webkit-scrollbar-track {
    background-color: transparent;
}